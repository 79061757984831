import './App.css';
import { useState } from "react";
import { Web3Button } from "@web3modal/react";
import { useAccount, useBalance, useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi';
import childhoodABI from "../assets/childhood.abi.json";
import { ethers, BigNumber } from "ethers";

function App() {
  const { address, isConnected } = useAccount();
  const [collection, setCollection] = useState({loading : true});
  const [item, setItem] = useState({loading : true});
  const [freemints, setFreemints] = useState(0);
  const [freemintsLoaded, setFreemintsLoaded] = useState(false);
  const [balance, setBalance] = useState(BigNumber.from(0));
  const [alreadyMinted, setAlreadyMinted] = useState(true);
  const [mintFinished, setMintFinished] = useState(false);

  useContractRead({
    address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
    abi: childhoodABI,
    functionName: 'collection',
    onError(error) {
      console.log('Error: ', error);
    },
    onSuccess(data) {
      setCollection(data);
    }
  });

  useContractRead({
    address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
    abi: childhoodABI,
    functionName: 'userFreemints',
    args: [address],
    onError(error) {
      console.log('Error: ', error)
    },
    onSuccess(data) {
      setFreemints(data.toNumber());
      setFreemintsLoaded(true);
    }
  });

  useContractRead({
    address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
    abi: childhoodABI,
    functionName: 'balanceOf',
    args: [address, collection.maxId],
    onError(error) {
      console.log('Error: ', error);
    },
    onSuccess(data) {
      setAlreadyMinted(data>0);
    }
  });

  useContractRead({
    address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
    abi: childhoodABI,
    functionName: 'items',
    args: [collection.maxId],
    onError(error) {
      console.log('Error: ', error)
    },
    onSuccess(data) {
      setItem(data);
    }
  });

  useBalance({
    address: address,
    onSuccess(data) {
      setBalance(data.value);
    }
  });

  function NFTPreview(props) {
    let nbmax = props.maxSupply;
    let nbdispo = 1;
    let nbtext = "";
    if (nbmax > 0) {
      nbdispo = nbmax - props.currentSupply;
      nbtext = "Il en reste " + nbdispo  + "/" + nbmax + " !";
    }
    return <div className="NFTPreview">
          <p>MINT en cours :</p>
          <img alt="NFT Preview" src={props.media.replace(
            "ipfs://",
            "https://nftstorage.link/ipfs/"
          )} />
          { nbmax > 0 && <p className="nftsupply">{nbtext}</p> }
          </div>;
  }

  function MintButton () {
    const [transaction, setTransaction] = useState(0);
    const [isPreparing, setIsPreparing] = useState(true);
    
    let ethValue = item.price;
    if ( freemints > 0 ) {
      ethValue = 0;
    }
    const { config } = usePrepareContractWrite({
      address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
      abi: childhoodABI,
      functionName: 'mintPostGenesis',
      args: [collection.maxId],
      overrides: {
        value: ethValue,
      },
      onSuccess(data) {
        setIsPreparing(false);
      },
      onError(error) {
        console.log('Error: ', error)
      }
    })
    const { isIdle, isLoading, isError, write } = useContractWrite({
        ...config,
        onSuccess(data) {
          setTransaction(data.hash);
          setMintFinished(true);
        }
    });

    return <>
      {isLoading && <div>...</div>}
      {(isIdle || isError ) && !alreadyMinted && !mintFinished && !isPreparing && <button disabled={!write} onClick={() => write?.()}>{item.price.gt(0) && !freemints ? "Mint" : "Freemint"}</button>}
      {mintFinished && <div className="TransactionLink">Tx sent</div> }
      </>;
  }

  function GenesisOnly() {
    const [userHoldsGenesisItems, setUserHoldsGenesisItems] = useState({loading: true});

    useContractRead({
      address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
      abi: childhoodABI,
      functionName: 'userHoldsGenesisItems',
      args: [address],
      onError(error) {
        console.log('Error: ', error)
      },
      onSuccess(data) {
        setUserHoldsGenesisItems(data);
      }
    });

    return <>{!userHoldsGenesisItems.loading && !userHoldsGenesisItems && "Ce mint est réservé aux holders de Genesis"}</>
  }

  function GenesisOrSequelOnly() {
    const [userHoldsSequelOrGenesisItems, setUserHoldsSequelOrGenesisItems] = useState({loading: true});

    useContractRead({
      address: process.env.REACT_APP_CHILDHOOD_CONTRACT,
      abi: childhoodABI,
      functionName: 'userHoldsSequelOrGenesisItems',
      args: [address],
      onError(error) {
        console.log('Error: ', error)
      },
      onSuccess(data) {
        setUserHoldsSequelOrGenesisItems(data);
      }
    });

    return <>{!userHoldsSequelOrGenesisItems.loading && !userHoldsSequelOrGenesisItems && "Ce mint est réservé aux holders de Genesis ou Sequels"}</>
  }

  return (
    <div className="App">
      <div className="Filler"><img alt="Tewoz TV background" src="/MintTewozLarge.jpg" /></div>
      {!item.loading && <NFTPreview id={collection.maxId} media={item.uri} currentSupply={item.currentSupply} maxSupply={item.maxSupply} /> }
      <div className={ isConnected ? "ConnBtnDiv ConnBtnDiv-connected" : "ConnBtnDiv" }><Web3Button /></div>
      <div className="MintPrice">{!item.loading && item.price.gt(0) && item.price.lt(ethers.utils.parseEther("1000")) && ethers.utils.formatEther(item.price)+" ETH"}</div>
      <div className="MintDiv">{ isConnected && collection.step===3 && freemintsLoaded ? <MintButton /> : <div>LOADING...</div>}</div>
      { !collection.loading && <div className="MintCount">NFT : {parseInt(collection.maxId)}</div>}

      <div className="MessageDiv">
        {isConnected && !collection.loading && 
          <>
          { collection.step!==3 && "Le mint n'est pas ouvert." }
          { collection.step===3 && !item.loading &&
            <>
              { (item.maxSupply.gt(0) && item.currentSupply.gte(item.maxSupply)) ? 
                "Sold out !!"
                :
                <>
                  { !mintFinished && freemints===0 &&  
                    <>
                      {item.price.gt(0) && item.price.lt(ethers.utils.parseEther("1000")) && item.price.gte(balance) && "Mint impossible : Pas assez d'ETH"} 
                      {item.price.eq(ethers.utils.parseEther("1000")) && "Ce mint est reservé"} 
                    </>
                  }
                  { item.genesisHolderOnlyMint && <GenesisOnly /> }
                  { item.genesisOrSequelHolderOnlyMint && <GenesisOrSequelOnly /> }
                  { alreadyMinted && <><br />On en laisse un peu pour les copains svp ;)</> }
                </>
              }
            </>
          }
          </>
        }
        {mintFinished && "Mint terminé ! Rechargez la page pour rejouer !"} 
      </div>
    </div>
  );
}

export default App;
