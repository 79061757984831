import './AdminPanel.css';

function AdminPanel() {
  return (
    <div className="App">
      Admin
    </div>
  );
}

export default AdminPanel;
