import { EthereumClient, w3mConnectors, w3mProvider} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, polygonMumbai } from "wagmi/chains";
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { Outlet } from "react-router-dom";


const chains = [mainnet];
//const chains = [polygonMumbai];
const projectId = process.env.REACT_APP_WCONNECT_ID;

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }), w3mProvider({ projectId }), publicProvider()])
//const { provider } = configureChains(chains, [w3mProvider({ projectId }), publicProvider()])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() { 
/*
  <nav>
  <ul>
    <li>
      <Link to="/">Mint</Link>
    </li>
    <li>
      <Link to="/admin">Admin</Link>
    </li>
  </ul>
</nav>
*/
return (
    <>

      <div className="App">
        <header className="App-header">
          <WagmiConfig client={wagmiClient}>
            <Outlet />
          </WagmiConfig>
        </header>
      </div>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  )
};

export default Layout;